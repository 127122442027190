import * as React from 'react';

import SEO from '../../components/seo';
import NavBar from '../../components/navBar';
import Footer from '../../components/Footer';
import ProductRow from '../../components/ProductRow';
import Goals1 from '../../images/app_goals_1.png';
import Goals2 from '../../images/app_goals_2.png';
import Goals3 from '../../images/app_goals_3.png';

// styles
const pageStyles = {
  color: '#232129',
  // padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <SEO
        title="Goals Calendar"
        description="The goal tracking app that helps you stay organized and focused. With a calendar style view, you can
        easily visualize all of your goals in one place and see when you are trying to do too much at once."
      />
      <NavBar />
      <div className="relative py-16 bg-white overflow-hidden">
        {/* <ContentSquares /> */}
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Goals
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              This app is different than the typical Streaks you are used to. The idea behind it, is to keep track how
              you <strong>feel after</strong> you work on a project/hobby.
            </p> */}
          </div>
          <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
            <p>
              The goal tracking app that helps you stay organized and focused. With a calendar style view, you can
              easily visualize all of your goals in one place and see when you are trying to do too much at once.
            </p>
            <p>
              You can breakout your goals by category, so you can stay on top of all areas of your life. Whether you're
              working on personal, professional, or even random fun goals, this app has you covered.
            </p>
            <p>
              So why wait? Download today and start reaching your goals faster and more efficiently than ever before!
            </p>
          </div>
        </div>
      </div>
      <ProductRow products={products} />
      <Footer />
    </div>
  );
};

export default IndexPage;

const products = [
  {
    id: 1,
    href: 'https://apps.apple.com/us/app/id1660459952',
    imageSrc: Goals1,
    eventName: 'image_1_goals_clicked',
  },
  {
    id: 2,
    href: 'https://apps.apple.com/us/app/id1660459952',
    imageSrc: Goals2,
    eventName: 'image_2_goals_clicked',
  },
  {
    id: 3,
    href: 'https://apps.apple.com/us/app/id1660459952',
    imageSrc: Goals3,
    eventName: 'image_3_goals_clicked',
  },
  // {
  //   id: 4,
  //   href: "https://apps.apple.com/us/app/id1660459952",
  //   imageSrc:
  //     "https://is4-ssl.mzstatic.com/image/thumb/PurpleSource122/v4/d5/20/c1/d520c1e1-1d7e-36b8-cc79-55f80fc42a4f/4320aba4-bb04-4f7a-b72d-7b726938f983_3.png/920x0w.webp",
  // },
];
